import React from 'react'

import Capital from '@components/pages/our-work/Capital'
import Consultants from '@components/pages/our-work/Consultants'
import Hero from '@components/pages/our-work/Hero'
import Root from '@layouts/Root'
import Seo from '@components/utils/Seo'
import Tools from '@components/pages/our-work/Tools'

const IndexPage = () => (
  <Root>
    <Seo title="Our Work" />

    <Hero />
    <div className="wrapper-l section">
      <hr />
    </div>
    <Tools />
    <Consultants />
    <Capital />
  </Root>
)

export default IndexPage
