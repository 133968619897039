import React from 'react'

import Intro from '@components/ui/Intro'
import Category from './Category'
import styles from './Consultants.module.scss'

const Consultants = () => (
  <section className="section wrapper-l">
    <Intro sectionTitle="Consultants" wrapper="wrapper-s">
      <p>
        Tools don’t just deliver themselves. How they are applied to analyzing
        business opportunities directly correlates to how effective they are.
        And that requires facilitation by specialists with experience. And some
        damn good instructions. It just so happens that we are specialists with
        lots of experience in instruction.
      </p>
    </Intro>

    <div className={styles.categoryWrapper}>
      <Category
        title="Workshops"
        cta={
          <a
            href="mailto:info@mosscc.org?subject=Consulting%20Workshop%20request"
            target="_blank"
            rel="noopener noreferrer"
          >
            Join or organize a workshop today
          </a>
        }
      >
        <p>
          We use our educational expertise to design engaging workshops that
          make applying our tools extremely easy.
        </p>
        <p>
          We start with the premise that any participant, regardless of
          educational background, should be able to understand and apply the
          latest scientific knowledge through our workshops.
        </p>
        <p>
          It’s not easy, but we make it work because we know what businesses
          find useful, we understand how adults learn, and we are adept at
          bringing across complex theory in an engaging way.
        </p>
      </Category>

      <Category
        title="Mentoring"
        cta={
          <a
            href="mailto:info@mosscc.org?subject=Consulting%20Mentoring%20request"
            target="_blank"
            rel="noopener noreferrer"
          >
            Find a knowledgeable friend today
          </a>
        }
      >
        <p>
          In one-on-one situations clients can take full advantage of our
          expertise and our consultants too can take full advantage of immersing
          themselves in the context of the client. It’s a level of exclusivity
          that suits everyone.
        </p>
        <p>
          MOSS’s business model puts into play the unique perspectives and
          diversity of various stakeholders in the role of consultants, starting
          with our in-house specialists, adding management-level volunteers, and
          providing support teams of business school graduates.
        </p>
        <p>
          Basically, MOSS provides you with a cross-sector Sustainability
          Department!
        </p>
      </Category>

      <Category
        title="Trainings"
        cta={
          <a
            href="mailto:info@mosscc.org?subject=Consulting%20Trainings%20request"
            target="_blank"
            rel="noopener noreferrer"
          >
            Become a systems specialist today
          </a>
        }
      >
        <p>
          Our tools are our commercial lifeline which means we are highly
          protective of them.
        </p>
        <p>
          However, one of the ways in which we scale our impact regionally is to
          share our knowledge through a train-the-trainer (TtT) model.
        </p>
        <p>
          For any given tool, MOSS provides hands-on interactive learning
          experiences that ensure trainees understand the supporting theory and
          can deliver quality workshops. Our tools are licensed annually so that
          trainees have access to content updates as well as ongoing support
          from MOSS.
        </p>
      </Category>
    </div>
  </section>
)

export default Consultants
