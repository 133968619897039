import React from 'react'
import PropTypes from 'prop-types'

import styles from './Tools.module.scss'

const Tool = ({ children, title, research }) => (
  <div className={styles.tool}>
    <h3 className={styles.title}>{title}</h3>

    <div className={styles.description}>{children}</div>

    <div className={styles.dropline}>
      <a
        href={`mailto:info@mosscc.org?subject=Tell%20me%20about%20${title}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Tell me more
      </a>
    </div>

    <div className={styles.research}>
      <h4 className={styles.research__title}>Based on research from:</h4>

      <ul className={styles.research__list}>
        {research.map((entry) => (
          <li key={entry}>{entry}</li>
        ))}
      </ul>
    </div>
  </div>
)

Tool.propTypes = {
  children: PropTypes.node.isRequired,
  research: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
}

export default Tool
