import React from 'react'
import PropTypes from 'prop-types'

import styles from './Category.module.scss'

const Category = ({ title, children, cta }) => (
  <div className={styles.category} id={title.toLowerCase()}>
    <h3 className={styles.title}>{title}</h3>

    <div className={styles.content}>{children}</div>

    <div className={styles.cta}>{cta}</div>
  </div>
)

Category.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  cta: PropTypes.node.isRequired,
}

export default Category
