import React from 'react'

import Intro from '@components/ui/Intro'
import Tool from './Tool'
import styles from './Tools.module.scss'

const Tools = () => (
  <section className="section wrapper-l">
    <Intro sectionTitle="Tools" wrapper="wrapper-s">
      <p>
        MOSS has developed a portfolio of tools built on cutting-edge scientific
        insights on responsible business. They have been painstakingly designed
        to make complex theory easy to understand and, more importantly,
        implementable.
      </p>
    </Intro>

    <div className={styles.toolsWrapper}>
      <Tool
        title="Systems Analysis"
        research={['MIT', 'Stanford University', 'Erasmus University']}
      >
        <p>
          This tool facilitates the basic mapping of a system using factor
          analysis in order to identify causal loops and key leverage points.
        </p>
        <p>
          By connecting the contextual factors that lead to dysfunctional system
          behavior, organizations can determine the most effective intervention
          points with which to nudge the system towards the change they want to
          see.
        </p>
        <p>
          Provides a fresh perspective of issues being tackled as well as new
          directions and focus areas for engagement.
        </p>
      </Tool>

      <Tool
        title="System Dynamics Modeling"
        research={['MIT', 'TU Delft', 'Villanova University']}
      >
        <p>
          This advanced Systems Thinking tool intensifies the understanding of
          the interrelations within a system and validates them using a
          multi-stakeholder approach.
        </p>
        <p>
          By modelling the stocks and flows, delays, and feedback loops of a
          given system, organizations can simulate intervention outcomes and
          more accurately forecast (coalition) resource requirements.
        </p>
        <p>
          Provides a simulated understanding of the complex dynamics of a system
          which affords new directions and increased impact certainty for
          multi-stakeholder interventions.
        </p>
      </Tool>

      <Tool
        title="Resilience for Systems"
        research={[
          'Cornell University',
          'University of Michigan',
          'University of Pennsylvania',
        ]}
      >
        <p>
          This tool utilizes resilience theory to manage systemic cross-scale
          change.
        </p>
        <p>
          By implementing managerial principles for building cross-scale
          resilience organizations are able to build capacity to work with
          change, instead of being victim to shocks and disturbances.
        </p>
        <p>
          Provides a method to understand the slow and fast changes to systems
          and identifies practicable options with which to build transformative
          and adaptive capacity.
        </p>
      </Tool>

      <Tool
        title="Stakeholder Value Mapping"
        research={[
          'University of Queensland',
          'Stockholm Resilience Centre',
          'George Washington University',
        ]}
      >
        <p>
          This tool takes a holistic view of an organization’s stakeholder
          relationships in order to identify overlooked negative systemic
          impact.
        </p>
        <p>
          By determining how value is destroyed or missed in the system through
          an organization’s relationships with key stakeholders, suitable
          mitigating steps and new avenues for value creation can be realized.
        </p>
        <p>
          Provides a nuanced perspective on stakeholder relationships,
          uncovering unnoticed value creation opportunities for systemic change.
        </p>
      </Tool>

      <Tool
        title="Societal Innovation"
        research={[
          'Stanford University',
          'London School of Economics',
          'University of Oxford',
        ]}
      >
        <p>
          This tool leans on social innovation and inclusive business theory to
          unpack systemic interventions into multilateral solutions that create
          social value.
        </p>
        <p>
          By leveraging cross-sector ideas and best practices for social
          progress, organizations can develop effective collaborative solutions
          to systemic social and environmental issues.
        </p>
        <p>
          Provides a novel approach to creating social value through system
          interventions by discovering innovative multi-party solutions.
        </p>
      </Tool>

      <Tool
        title="Sustainable Business Modeling"
        research={[
          'Syracuse University',
          'University of St. Gallen',
          'Concordia University',
        ]}
      >
        <p>
          This tool investigates potential business model implications of system
          interventions and their socio-ecological ramifications.
        </p>
        <p>
          By analyzing and comparing the social and environmental significance
          of different business models, organizations can select the most
          appropriate business approach to enacting systemic change.
        </p>
        <p>
          Provides a way to map and modify an organization’s business model in
          line with an intended system intervention by accounting for
          socio-ecological impact.
        </p>
      </Tool>

      <Tool
        title="Sustainable Finance"
        research={[
          'University of Oxford',
          'University of Pennsylvania',
          'HEC Paris',
        ]}
      >
        <p>
          This tool facilitates the financial analysis of organizations beyond
          traditional financial ratios in order to unearth new value capture
          opportunities.
        </p>
        <p>
          By applying a systems perspective in scrutinizing an organization’s
          financial statements, unexpected value capture opportunities
          materialize that can reduce costs, reveal new revenue streams,
          capitalize on underutilized assets, and generate socio-ecological
          impact.
        </p>
        <p>
          Provides a systems lens to financial analysis that uncovers latent
          value capture opportunities and directions for securing
          socio-ecological value.
        </p>
      </Tool>

      <Tool
        title="Systemic Impact Measurement"
        research={[
          'Harvard Business School',
          'Stanford University',
          'University of Oxford',
        ]}
      >
        <p>
          This tool provides a bricolage approach to performance measurement
          through a systems-based design to determine what, how, and why to
          measure impact.
        </p>
        <p>
          By consolidating a myriad of cutting-edge social impact measurement
          methodologies into a single framework, organizations can determine the
          scope of their measurement requirements vis-à-vis key stakeholders and
          use systems-based techniques for setting and collecting impact data.
        </p>
        <p>
          Provides a comprehensive process to develop and enact a valid, useful,
          and lean impact measurement strategy.
        </p>
      </Tool>

      <Tool
        title="Scaling Systemic Impact"
        research={['Stanford University', 'York University', 'Duke University']}
      >
        <p>
          This tool shifts the paradigm of mainstream impact scaling narratives,
          providing a profusion of systemic scaling options contingent on
          organizational readiness.
        </p>
        <p>
          By defining their innovation as well as diagnosing their situational
          contingencies (such as lifecycle phase), organizations can choose
          centralized or distributed scaling options that best scale their
          impact systemically.
        </p>
        <p>
          Provides a scaling readiness diagnosis as well as systems-based
          methods to scale impact (scale up) rather than scale solutions (scale
          out).
        </p>
      </Tool>
    </div>
  </section>
)

export default Tools
