import React from 'react'

import Intro from '@components/ui/Intro'

const Hero = () => (
  <header className="hero t-center">
    <Intro pageTitle="Our Work" wrapper="wrapper-m">
      <p className="t-heading-xxs t-muted">We build resilience.</p>
      <br />

      <p>
        We put social enterprises in a strong position to outlast the
        competition in volatile frontier markets. In whatever way you’re
        connected to social entrepreneurship – as an investor or funder, a
        beneficiary, an NGO, an education or government institution, the
        entrepreneur themselves, or even an employee – that’s peace of mind.
      </p>

      <p>
        That’s because we help them find the key levers to swing, the right
        buttons to push, the most effective strings to pull, in order to address
        the root source of the socio-ecological problems they are eager to
        solve. So where others waste resources mopping up the water, our clients
        focus on fixing the leaks.
      </p>

      <br />

      <p className="t-italic">
        That’s maximum impact.
        <br />
        Think about it. We do.
      </p>
    </Intro>
  </header>
)

export default Hero
