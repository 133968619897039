import React from 'react'

import Intro from '@components/ui/Intro'
import Columns from '@components/ui/Columns'
import styles from './Capital.module.scss'

const Capital = () => (
  <section className="section">
    <Intro sectionTitle="Capital" wrapper="wrapper-m">
      <p className="t-bold t-center">
        Ah yes, the money. It’s in our name, but you know by now that it’s not
        what MOSS is all about.
      </p>

      <br />

      <Columns>
        <p className="t-justify">
          Inevitably, Social Enterprises (SEs) working with our consultants will
          uncover opportunities to fundamentally increase their socio-ecological
          impact. Some of the common obstacles to realizing such high-impact
          opportunities involve capital limitations. MOSS aims to provide SEs
          with access to capital in such situations.
        </p>

        <p className="t-justify">
          We do so by seeking collaborations with local and international
          funding bodies, working within existing financial infrastructures, and
          mediating on behalf of the SE and the identified funding opportunity.
          Alternatively, MOSS may choose to extend the capital through its
          revolving fund. We just don’t have one. Yet.
        </p>
      </Columns>
    </Intro>

    <div className={styles.cta}>
      <a
        className={styles.cta}
        href="mailto:info@mosscc.org?subject=Capital%20Inquiry"
        rel="noopener noreferrer"
        target="_blank"
      >
        Inquire about capital opportunities
      </a>
    </div>
  </section>
)

export default Capital
